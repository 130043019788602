import Link from 'next/link';
import React, { useRef } from 'react';

import styled, { css } from 'styled-components';

import TextEditor from '@/components/TextEditor';
import type { PolicyCardProps } from '@/utilities/strapi/types/componentTypes';

const policyCardCSS = css<{ $subTextColor?: 'gray' | 'dark' }>`
  --margin: 4rem;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 4rem;
  text-align: left;
  border-radius: 3rem;
  position: relative;

  @media (max-width: 768px) {
    --margin: 0;
    padding: 3rem;
  }

  a > *:not([class*='CTAButton']) {
    padding-bottom: 1.8rem;
  }

  .block {
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 768px) {
      img {
        max-width: 2.4rem;
        display: inline-block;
        margin-right: 1.5rem;
        padding-bottom: 0;
        vertical-align: top;
      }

      img + .heading {
        width: calc(100% - 3.9rem);
        display: inline-block;
      }
    }
  }

  .heading {
    font: var(--font-family);
    font-size: 3rem;
    line-height: 4rem;
    font-weight: var(--font-weight-normal);
    letter-spacing: -0.02em;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.04rem;
      position: relative;
    }
  }

  .text-base {
    font: var(--font-family);
    font-size: 1.5rem;
    font-weight: var(--font-weight-normal);
    line-height: 2rem;
    letter-spacing: -0.01em;

    color: ${({ $subTextColor }) => ($subTextColor === 'gray' ? 'var(--color-text-body-secondary)' : undefined)};
    margin: 0;

    &:last-child {
      padding-bottom: 0;
    }

    @media (max-width: 768px) {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.016rem;
    }
  }

  > [class*='TextEditor'] {
    > [class*='CTAButton'] {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const PolicyLinkCard = styled.a`
  ${policyCardCSS}
`;

const PolicyCard = styled.div`
  ${policyCardCSS}
`;

export function PolicyRichCard({ body, href }: PolicyCardProps) {
  const root = useRef<HTMLAnchorElement | HTMLDivElement>(null);

  if (href) {
    return (
      <Link href={href} passHref>
        <PolicyLinkCard ref={root as React.RefObject<HTMLAnchorElement>} $subTextColor="gray">
          <TextEditor text={body} />
        </PolicyLinkCard>
      </Link>
    );
  }

  return (
    <PolicyCard ref={root as React.RefObject<HTMLDivElement>}>
      <TextEditor text={body} />
    </PolicyCard>
  );
}
