import clsx from 'clsx';
import React from 'react';

import styled from 'styled-components';

import type { StrapiDataObject } from '@/utilities/strapi/types/apiResponseTypes';
import type { CallToActionButton, CallToActionButtonProps as Props } from '@/utilities/strapi/types/componentTypes';

import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';

import StrapiLink from '../../utils/StrapiLink';

export const StyledStrapiLink = styled(StrapiLink)<{ shape?: CallToActionButton['shape'] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: ${({ shape }) => (shape === 'round' ? '4rem' : '.5rem')};
  cursor: pointer;
  font: var(--font-label-2);
  line-height: 1;
  letter-spacing: -0.032rem;
  padding: 2rem 2.8rem;
  text-align: center;
  position: relative;
  width: fit-content;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 1rem 1.4rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
  }

  &.large,
  &.lg {
    padding: var(--spacing-base);
  }

  &.medium,
  &.md {
    padding: 1.9rem 2.8rem;
  }

  &.full-width {
    width: 100%;
  }

  &.sm,
  &.small {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.048rem;
    padding: 1rem 1.4rem;
  }

  &.ghost {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.15);
  }

  &.primary {
    background-color: var(--themed-background-color, var(--color-action-default));
    border-color: var(--themed-background-color, var(--color-action-default));
    color: var(--themed-color, var(--color-action-inverse));
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid currentColor;
    color: currentColor;
    overflow: hidden;

    &:before {
      background-color: var(--themed-background-color, var(--color-action));
      border-radius: ${({ shape }) => (shape === 'round' ? '4rem' : '.5rem')};
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      transform: translateY(100%);
      transition: transform var(--animation-duration-fast) var(--ease-in-out-cubic);
    }

    &:hover {
      border-color: var(--themed-background-color, var(--color-action));
      color: var(--themed-color, var(--color-action-contrast));

      &:before {
        transform: translateY(0);
      }
    }
  }

  &.light {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
  }

  &.text-only {
    border: none;
    background: none;
  }

  &.white-label {
    color: var(--color-white);
  }

  .label-wrapper {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      content: attr(data-label);
      content: attr(data-label) / '""';
      alt: '""';
      position: absolute;
      top: 0;
      left: 0;
      transition: transform var(--animation-duration) var(--ease-in-out-cubic);
    }

    &:before {
      transform: translateY(0%);
    }

    &:after {
      transform: translateY(150%);
    }
  }

  &:hover {
    .label-wrapper {
      &:before {
        transform: translateY(-150%);
      }

      &:after {
        transform: translateY(0%);
      }
    }
  }

  &:focus-visible {
    outline: var(--focus-outline);
    outline-width: 2px;
    outline-offset: var(--focus-outline-offset);
  }

  .label {
    opacity: 0;
    line-height: 1.1;
  }
`;

export default function CTAButton({
  label,
  page,
  style,
  external_url: externalUrl,
  className,
  genericClickDetails,
  anchorId,
  target,
  shape = 'round',
}: Props) {
  const pageData = (page as StrapiDataObject<StrapiMinimalPageInfo>)?.data?.attributes || page;
  return (
    <StyledStrapiLink
      page={pageData}
      externalUrl={externalUrl}
      anchor={anchorId}
      className={clsx(className, style?.toString?.() || 'primary', 'button')}
      genericClickDetails={genericClickDetails}
      useChildren
      target={target}
      shape={shape}
    >
      <div data-label={label} className="label-wrapper">
        <span className="label">{label}</span>
      </div>
    </StyledStrapiLink>
  );
}
