import type { PolicySectionContainer } from '@/components/PolicySectionContainer';
import type PricingCommerceCarousel from '@/components/Pricing/PricingCommerceCarousel';

import type { StrapiDataObject, StrapiIntegration } from './apiResponseTypes';
import type { StrapiMinimalPageInfo, StrapiIntegrationAttributes } from './queryResponseTypes';
import type { BackgroundColorProps, Link } from './utilsTypes';

export interface ComponentBase {
  id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO this property name should be remapped during service response mapping
  __component: string;
}

export type StrapiComponent =
  | AppStoreLinks
  | QRCode
  | ArticleTeaser
  | RelatedArticles
  | SocialShareLinks
  | MediaWithMeta
  | PromoTile
  | TitleAndBlurb
  | CreatorHero
  | CreatorCarousel
  | CreatorGrid
  | FeaturesHero
  | LogoShowcase
  | PricingTextRichCards
  | PricingRichCard
  | PricingTierCard
  | PricingPlans
  | TextEditor
  | UtilsOneColumnText
  | UtilsTwoColumnText
  | UtilsThreeColumnText
  | SectionBreak
  | CallToActionButton
  | HomeHero
  | ImageGridWithTitle
  | FullBleedTestimonial
  | GetStarted
  | FloatingProductUI
  | TitleAndLogoLockup
  | IntegrationPromoTilesDirectory
  | IntegrationPromoTilesCarousel
  | PricingCommerceCarousel
  | PricingFeaturesCarousel
  | CookieEditor
  | PolicyCards
  | PolicySectionContainer
  | Error;

export interface AppStoreLinksProps {
  appleAppStoreLink?: Link;
  appleAppStoreBadge?: ImageWithMeta;
  appleAppStoreBadgeLight?: ImageWithMeta;
  googleAppStoreLink?: Link;
  googleAppStoreBadge?: ImageWithMeta;
  googleAppStoreBadgeLight?: ImageWithMeta;
}
export type AppStoreLinks = AppStoreLinksProps & ComponentBase;

export interface FormFactor {
  screenSize: string;
}
export type FormFactorProps = FormFactor & ComponentBase;

export interface QRCodeProps {
  promptTitle: string;
  image: MediaWithMeta;
}
export type QRCode = QRCodeProps & ComponentBase;

export interface AnchorIdProps {
  anchorId: string;
}
export type AnchorId = AnchorIdProps & ComponentBase;

export interface VerticalAlignment {
  alignment: string;
}
export type VerticalAlignmentProps = VerticalAlignment & ComponentBase;
export interface ArticleTeaserProps {
  headline: string;
  category?: string;
  previewImage: ImageWithMeta;
  link: Link;
}
export type ArticleTeaser = ArticleTeaserProps & ComponentBase;

export interface RelatedArticlesProps {
  header: string;
  articles: ArticleTeaser[];
}
export type RelatedArticles = RelatedArticlesProps & ComponentBase;

export interface SocialShareLinksProps {
  title: string;
  shareTitle: string;
  shareMessage: string;
}
export type SocialShareLinks = SocialShareLinksProps & ComponentBase;

export interface MediaWithMetaProps {
  id: number;
  caption?: string;
  media?: StrapiMedia;
  attributes: {
    name: string;
    alternativeText: null;
    caption: null;
    width: null;
    height: null;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: null;
    provider: string;
    provider_metadata: null;
    createdAt: string;
    updatedAt: string;
    colors: null;
  };
  anchorId?: AnchorId;
}
export type MediaWithMeta = MediaWithMetaProps & ComponentBase;

export enum ColumnLayout {
  'Ratio - 50/50' = 'Ratio - 50/50',
  'Ratio - 66/33' = 'Ratio - 66/33',
  'Ratio - 33/66' = 'Ratio - 33/66',
}
export interface PromoTileProps {
  text: TextEditorProps;
  Media: MediaWithMeta;
  layout: ColumnLayout;
  verticalAlignment?: VerticalAlignment;
}
export type PromoTile = PromoTileProps & ComponentBase;

export enum TitleAndBlurbLayout {
  LeftOverflow = 'Left Overflow Title',
  LeftAligned = 'Left Aligned Title',
  LeftOffset = 'Left Aligned Offset Title',
}

export interface TitleAndBlurbProps {
  Title: string;
  Blurb: TextEditorProps;
  Layout: TitleAndBlurbLayout;
  anchorId?: AnchorId;
  titleSize?: 'sm' | 'md' | 'lg' | 'xl';
}
export type TitleAndBlurb = TitleAndBlurbProps & ComponentBase;

export interface TextEditorProps {
  text?: string | null;
  // TODO: Add ability to configure `minHeadingLevel` and `size` in the CMS.
  minHeadingLevel?: number;
  size?: 'default' | 'large';
}
export type TextEditor = TextEditorProps & ComponentBase;

export interface UtilsOneColumnTextProps {
  content?: TextEditorProps;
  fullWidth?: boolean;
  className?: string;
  anchorId?: AnchorId;
}
export type TitleBlock = TitleBlockProps & ComponentBase;

export interface TitleBlockProps {
  title: string;
  backgroundGradientColor?: BackgroundColorProps;
}
export type UtilsOneColumnText = UtilsOneColumnTextProps & ComponentBase;

export interface SectionBreakProps {
  size: 'small' | 'medium' | 'large';
}
export type SectionBreak = SectionBreakProps & ComponentBase;

export interface UtilsTwoColumnTextProps {
  column_one: TextEditorProps;
  column_two: TextEditorProps;
  layout: 'Ratio - 50/50' | 'Ratio - 66/33' | 'Ratio - 33/66';
  verticalAlignment?: VerticalAlignment;
  anchorId?: AnchorId;
}
export type UtilsTwoColumnText = UtilsTwoColumnTextProps & ComponentBase;

export interface UtilsThreeColumnTextProps {
  column_one: TextEditorProps;
  column_two: TextEditorProps;
  column_three: TextEditorProps;
  anchorId?: AnchorId;
  verticalAlignment?: VerticalAlignment;
}
export type UtilsThreeColumnText = UtilsThreeColumnTextProps & ComponentBase;

export interface FourColumnTextProps {
  heading_section: TextEditorProps;
  column: Array<{ id: string; text: string; link?: Link }>;
  anchorId?: AnchorId;
}

export type UtilsFourColumnText = FourColumnTextProps & ComponentBase;

export interface CallToActionButtonProps {
  external_url?: string;
  label: string;
  style?: string;
  genericClickDetails?: string;
  page?: StrapiDataObject<StrapiMinimalPageInfo> | StrapiMinimalPageInfo;
  className?: string;
  anchorId?: string;
  target?: '_blank' | '_self';
  shape?: 'round' | 'square';
}
export type CallToActionButton = CallToActionButtonProps & ComponentBase;

export interface MediaFormat {
  name?: string;
  hash?: string;
  ext?: string;
  mime: string;
  path?: string | null;
  width?: number | null;
  height?: number | null;
  size: number;
  url: string;
}

export type ISO8601DateString = string;
export type HexColorString = string;

type TupleOf<T, N extends number> = N extends N ? (number extends N ? T[] : _TupleOf<T, N, []>) : never;
// eslint-disable-next-line @typescript-eslint/naming-convention
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N ? R : _TupleOf<T, N, [T, ...R]>;

export type MediaColors = TupleOf<HexColorString, 4>;

export interface MediaAttributes extends MediaFormat {
  alternativeText: string;
  caption?: string;
  formats?: Record<string, MediaFormat> | null;
  previewUrl?: string | null;
  provider: string;
  colors?: MediaColors | null;
  createdAt?: ISO8601DateString;
  updatedAt?: ISO8601DateString;
}

export interface StrapiMedia {
  data?: {
    id: number;
    attributes: MediaAttributes;
  } | null;
}

export interface ImageWithMeta {
  id: number;
  alternativeText?: string;
  image?: StrapiMedia;
}

export interface ImageBlockAttributes {
  alt: string;
  formats: Record<string, MediaFormat> | null;
  height: number;
  width: number;
  mime: string;
  size: number;
  url: string;
}
export interface ImageBlockWithMeta {
  caption?: string;
  image: {
    data: {
      attributes: ImageBlockAttributes;
    };
  };
}

export interface HomeHeroProps {
  id: number;
  Items: Array<{
    id: number;
    Title: string;
    Creator: CallToActionButton;
    CreatorDescription: string;
    ProfilePic?: ImageWithMeta;
    Media?: MediaWithMeta;
    MediaFallbackImage?: ImageWithMeta;
    useCustomCta?: boolean;
    CustomCtaText?: string;
    CustomCtaButton?: CallToActionButton;
  }>;
}
export type HomeHero = HomeHeroProps & ComponentBase;

export interface CreatorCarouselProps {
  id: number;
  title: string;
  body: TextEditorProps;
  slides: Array<{
    id: number;
    link: Link;
    background: ImageWithMeta;
    quote?: string;
    quoteAuthor?: string;
  }>;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
  showCarouselButtons?: boolean;
}
export type CreatorCarousel = CreatorCarouselProps & ComponentBase;

export interface CreatorHeroProps {
  id: number;
  title: string;
  background: MediaWithMeta;
  body: TextEditorProps;
  titleStyle?: 'default' | 'flat';
  bodyPlacement?: 'left' | 'right';
}
export type CreatorHero = CreatorHeroProps & ComponentBase;

export interface FeaturesHeroProps {
  id: number;
  title: string;
  background: MediaWithMeta;
  body: TextEditorProps;
  productui?: MediaWithMeta[];
  backgroundColor?: BackgroundColorProps;
}
export type FeaturesHero = FeaturesHeroProps & ComponentBase;

export interface PricingTextRichCardsProps {
  id: number;
  copy: string | null;
  title: string;
  bottomCopy: string | null;
  pricingTextCards: Array<{ id: number; body?: string | null }>;
  anchorId?: AnchorId;
  backgroundGradientColor?: BackgroundColorProps;
  grouped?: boolean;
}

export type RichTextCards = RichTextCardProps & ComponentBase;

export interface RichTextCardProps {
  id: number;
  copy: string | null;
  title: string;
  bottomCopy: string | null;
  cards: Array<{ id: number; body?: string | null }>;
  anchorId?: AnchorId;
  backgroundGradientColor?: BackgroundColorProps;
  grouped?: boolean;
}

export type PricingTextRichCards = PricingTextRichCardsProps & ComponentBase;

export interface PricingPlansProps {
  id: number;
  body: string | null;
  title: string;
  prices: Array<{ id: number; title: string; highlight?: string; body: string | null }>;
  anchorId?: AnchorId;
  backgroundGradientColor?: BackgroundColorProps;
}

export type PricingPlans = PricingPlansProps & ComponentBase;

export interface PricingTierCardProps {
  text?: string | null;
  title: string;
  highlight?: string;
  className?: string;
}
export type PricingTierCard = PricingTierCardProps & ComponentBase;

export interface PricingRichCardProps {
  text?: string | null;
  className?: string;
}
export type PricingRichCard = PricingRichCardProps & ComponentBase;

export interface CreatorGridProps {
  id: number;
  title: string;
  title2: string;
  body: TextEditorProps;
  creators: Array<{
    id: number;
    creator: Link;
    media: MediaWithMeta;
  }>;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
}
export type CreatorGrid = CreatorGridProps & ComponentBase;

export interface LogoShowcaseProps {
  id: number;
  title: string;
  body: TextEditorProps;
  logos: ImageWithMeta[];
  backgroundColor?: BackgroundColorProps;
}
export type LogoShowcase = LogoShowcaseProps & ComponentBase;

export interface TitleAndLogoLockupProps {
  id: number;
  title: string;
  logos: ImageWithMeta[];
  body: TextEditorProps;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
}
export type TitleAndLogoLockup = TitleAndLogoLockupProps & ComponentBase;

export interface ImageGridWithTitleProps {
  id: number;
  title: string;
  body1: TextEditorProps;
  body2: TextEditorProps;
  images: ImageWithMeta[];
  backgroundColor?: BackgroundColorProps;
}
export type ImageGridWithTitle = ImageGridWithTitleProps & ComponentBase;

export interface FullBleedTestimonialProps {
  title?: string;
  quote: string;
  quoteAuthor: string;
  quoteAttribution: string;
  featuredImage: ImageWithMeta;
  theme: 'light' | 'dark';
  anchorId?: AnchorId;
  quotePlacement?: 'right' | 'left';
}
export type FullBleedTestimonial = FullBleedTestimonialProps & ComponentBase;

export interface GetStartedProps {
  title: string;
  background: MediaWithMeta;
  anchorId?: AnchorId;
  customCta?: string;
}
export type GetStarted = GetStartedProps & ComponentBase;

export enum FloatingProductUILayout {
  TopLeft = 'Top Overflow Header + Left Product UI',
  TopLeftTight = 'Top Overflow Header + Left Product UI - Tight',
  TopRight = 'Top Overflow Header + Right Product UI',
  RightLeft = 'Right Header + Left Product UI',
  Right = 'Product UI on Right',
  SplitCenter = 'Split Header + Center Product',
  LeftBottom = 'Left Aligned Header + Bottom Product UI',
}

export interface FloatingProductUIProps {
  id: number;
  Title: string;
  Layout?: FloatingProductUILayout;
  ProductUI: MediaWithMeta[];
  Column1: TextEditorProps;
  Column2?: TextEditorProps;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
  formFactor?: FormFactorProps;
  alignColumns?: 'left' | 'center' | 'right';
}

export type FloatingProductUI = FloatingProductUIProps & ComponentBase;

export interface ContentContainerProps {
  children: React.ReactNode;
  className?: string;
  component: StrapiComponent;
}
export type ContentContainer = ContentContainerProps & ComponentBase;

export interface SiteSettingsProps {
  page: StrapiMinimalPageInfo;
  alternates: Array<{
    hrefLang: string;
    href: string;
  }>;
}
export type SiteSettings = SiteSettingsProps & ComponentBase;

export interface IntegrationPromoTilesDirectoryProps {
  title: string;
  body: string;
  integrationsById: Record<number, StrapiIntegrationAttributes>;
  anchorId?: AnchorId;
}
export type IntegrationPromoTilesDirectory = IntegrationPromoTilesDirectoryProps & ComponentBase;

export interface IntegrationPromoTilesCarouselProps {
  title: string;
  body: string;
  integrations: { data: StrapiIntegration[] };
}

export type IntegrationPromoTilesCarousel = IntegrationPromoTilesCarouselProps & ComponentBase;

export interface CarouselProps {
  id?: number;
  title: string;
  body: string;
  slides: Array<{
    id: number;
    title: string;
    body: string;
    image: { data: { attributes: ImageBlockAttributes } };
  }>;
}
export type Carousel = CarouselProps & ComponentBase;

export interface PricingFeaturesCarouselProps {
  id: number;
  title: string;
  body: string;
  slides: Array<{
    id: number;
    title: string;
    image: ImageWithMeta;
  }>;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
}

export type PricingFeaturesCarousel = PricingFeaturesCarouselProps & ComponentBase;

export interface PricingCommerceCarouselProps {
  id: number;
  title: string;
  body: string;
  slides: Array<{
    id: number;
    title: string;
    image: ImageWithMeta;
    body: string;
  }>;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
}

export type PricingCommerceCarousel = PricingCommerceCarouselProps & ComponentBase;

export interface PhoneScreenCarouselProps {
  id: number;
  title: string;
  body: string;
  slides: Array<{
    id: number;
    title: string;
    image: ImageWithMeta;
    body: string;
  }>;
  anchorId?: AnchorId;
  backgroundColor?: BackgroundColorProps;
}

export type PhoneScreenCarousel = PhoneScreenCarouselProps & ComponentBase;

export interface CookieEditorProps {
  label: string;
  query_key: string;
  query_value: string;
  cta_type: 'edit_cookies' | string;
  style: 'primary' | 'secondary';
}

export type CookieEditor = CookieEditorProps & ComponentBase;

export interface PolicySectionContainerProps {
  header: string;
  footer_link: {
    text: string;
    externalUrl?: string;
    page?: {
      data: {
        attributes: {
          Slug: string;
          Title: string;
        };
      };
    };
  };
  policy_section_child: Array<{ id: string; content: string; header: string; anchorId: string }>;
}

export type PolicySectionContainer = PolicySectionContainerProps & ComponentBase;

export interface PolicyCardProps {
  id: string;
  body: string;
  href?: string;
}
export type PolicyCard = PolicyCardProps & ComponentBase;

export interface PolicyCardsProps {
  link_card: PolicyCardProps[];
}

export type PolicyCards = PolicyCardsProps & ComponentBase;

export type Error = ErrorProps & ComponentBase;

export interface ErrorProps {
  errorCode: string;
  description: string;
  ctaHeading: string;
}
export interface FaqComponent {
  title: string;
  body?: string;
  sections: Array<{
    heading: string;
    content: string;
  }>;
  backgroundColor?: BackgroundColorProps;
  alignTitle?: 'top' | 'left';
}
export type FaqProps = FaqComponent & ComponentBase;
