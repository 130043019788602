import { Collapse, ThemeProvider } from '@patreon/studio';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import MinusIcon from '@/components/icons/minus.svg';
import PlusIcon from '@/components/icons/plus.svg';
import BackgroundColors from '@/shared/constants/BackgroundColors';
import type { FaqComponent as Props } from '@/utilities/strapi/types/componentTypes';

import GradientBackground from '../Hydra/GradientBackground';
import TextEditor from '../TextEditor';

import LineBreakTitle from '../utils/LineBreakTitle';

export function Faq({ title, body, sections, backgroundColor, alignTitle = 'left' }: Props) {
  return (
    <div>
      <Background colors={backgroundColor || BackgroundColors.black} targetLightness={85} />
      <ContentContainer direction={alignTitle === 'left' ? 'row' : 'column'}>
        <TitleAndBodyWrapper isStacked={false}>
          <LineBreakTitle align="alternating" className="mb-4" size={alignTitle === 'left' ? 2 : 1}>
            {title}
          </LineBreakTitle>
          {body ? <TextEditor text={body} /> : null}
        </TitleAndBodyWrapper>

        <div>
          {sections?.map((section) => (
            <CollapseSection {...section} key={section.heading} />
          ))}
        </div>
      </ContentContainer>
    </div>
  );
}

const CollapseSection = ({ heading, content }: { heading: string; content: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <ThemeProvider>
      <CollapseContainer>
        <h5>
          <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {heading}
            {isCollapsed ? <Plus /> : <Minus />}
          </CollapseButton>
        </h5>
        <Collapse isCollapsed={isCollapsed}>
          <TextEditor size="large" text={content} />
        </Collapse>
      </CollapseContainer>
    </ThemeProvider>
  );
};

const ContentContainer = styled.div<{ direction: 'row' | 'column' }>`
  position: relative;
  width: 100%;
  min-height: clamp(50vw, 100vh, 70vw);
  padding: max(100vh - 90vw, 17rem) max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess)))
    5rem max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: flex-end;
  gap: ${({ direction }) => (direction === 'column' ? '2.5rem' : '8rem')};
  margin-bottom: ${({ direction }) => (direction === 'column' ? '9rem;' : undefined)};

  h5 {
    font-size: var(--font-size-h5);
    max-width: 100%;
    margin-bottom: 1rem;
  }

  p {
    font-size: var(--font-size-body);
  }

  .collapsible {
    overflow: hidden;
  }

  .mb-4 {
    margin-bottom: 4rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 14rem max(env(safe-area-inset-right), 2rem) calc(2rem + var(--browser-chrome-height))
      max(env(safe-area-inset-left), 2rem);
    min-height: 100dvh;
  }
`;

const CollapseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  text-align: inherit;
  widthL 100%;
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  width: 100%;
`;

const CollapseContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  border-bottom: var(--border);
  padding: 1rem 0;

  @media (max-width: 768px) {
    --font-size-body-lg: var(--font-size-body);
  }
`;

const Plus = styled(PlusIcon)`
  margin-left: 0.5rem;
  vertical-align: middle;
  width: 2.2rem;
  height: 2.2rem;
`;

const Minus = styled(MinusIcon)`
  margin-left: 0.5rem;
  vertical-align: middle;
  width: 2.2rem;
  height: 2.2rem;
`;

const Background = styled(GradientBackground)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const TitleAndBodyWrapper = styled.div<{ isStacked?: boolean }>`
  ${({ isStacked }) =>
    !isStacked
      ? css`
          max-width: 500px;

          h1 {
            margin-top: 0;
          }
        `
      : null}
`;
