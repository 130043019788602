import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import TextEditor from '@/components/TextEditor';
import RightIcon from '@/components/icons/arrow-right.svg';
import type { CarouselProps } from '@/utilities/strapi/types/componentTypes';

import Button from '../Elements/Buttons/Button';
import ResponsiveImage from '../Elements/ResponsiveImage';

export const Carousel = ({ title, body, slides }: CarouselProps) => {
  const [index, setIndex] = useState(0);
  const sliderParams = useRef({ visibleSlides: 0, percent: 0 });

  useEffect(() => {
    let prevStatus: boolean | number = -1;

    const fn = () => {
      const width = window.innerWidth;
      const status = width < 768;
      sliderParams.current.visibleSlides = width < 768 ? 1 : 3;
      sliderParams.current.percent = width < 768 ? 100 : 33.333;
      if (prevStatus !== status) {
        if (prevStatus !== -1) {
          const limit = slides.length - sliderParams.current.visibleSlides;
          setIndex((s) => (s < 0 ? 0 : s > limit ? limit : s));
        }
        prevStatus = status;
      }
    };

    fn();

    window.addEventListener('resize', fn);

    return () => window.removeEventListener('resize', fn);
  }, [slides]);

  function goNext() {
    setIndex((state) => (state < slides.length - sliderParams.current.visibleSlides ? state + 1 : state));
  }

  function goPrev() {
    setIndex((state) => (state > 0 ? state - 1 : state));
  }

  return (
    <StyledWrapper>
      <div className="carousel-head">
        <h1 className="carousel-title">{title}</h1>
        <p className="carousel-body">{body}</p>
      </div>
      <div className="carousel-slider">
        <div
          className={clsx('carousel-track', slides.length < 3 && 'justify-center')}
          style={{ transform: 'translateX(' + -1 * sliderParams.current.percent * index + '%)' }}
        >
          {slides.map(({ id, title, body, image }) => (
            <div className="promo-tile" key={id}>
              <div className="promo-tile-img-wrap">
                {image ? (
                  <ResponsiveImage
                    image={{ image }}
                    className="promo-tile-img"
                    sizes="(max-width: 768px) 100vw, 320px"
                  />
                ) : (
                  <div className="promo-tile-placeholder" />
                )}
              </div>
              <div className="promo-tile-body">
                <div className="promo-tile-title">{title}</div>
                <div className="promo-tile-desc">
                  <TextEditor text={body} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {slides.length > 3 && (
        <div className="carousel-slider-nav">
          <Button icon={<RightIcon />} onClick={goPrev} label="Previous" hideLabel size="lg" className="arrow-left" />
          <Button icon={<RightIcon />} onClick={goNext} label="Next" hideLabel size="lg" className="arrow-right" />
        </div>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  --font-body-light: 300 max(11px, 1.6rem) / 140% var(--font-family-heading);

  /* TODO: use rem */
  padding-top: 72px;

  display: grid;
  width: 100%;
  max-width: 1170px;
  padding-left: 10rem;
  padding-right: 10rem;
  width: 100%;
  margin: 0 auto;

  .carousel-head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .carousel-title {
    font: var(--font-h5);
    letter-spacing: -0.05em;
    margin-bottom: 0.8rem;
    font-weight: var(--font-weight-h1);
  }

  .carousel-body {
    font: var(--font-body-light);
  }

  .carousel-slider {
    margin-top: 5rem;
    overflow: hidden;
  }

  .carousel-track {
    display: flex;
    margin-right: -4.5rem;
    margin-left: -4.5rem;

    .justify-center {
      justify-content: center;
    }
  }

  .promo-tile {
    display: flex;
    flex-direction: column;
    width: 33.3333%;
    padding: 0 4.5rem;
    flex: 0 0 auto;
    height: fit-content;
  }

  .promo-tile-img-wrap {
    position: relative;
    height: 0;
    padding-top: 100%;
    width: 100%;
    overflow: hidden;
  }

  .promo-tile-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .promo-tile-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 8%;
    top: 0;
  }

  .promo-tile-body {
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .promo-tile-title {
    font: var(--font-label-1);
    margin-bottom: 0.4rem;
  }

  .promo-tile-desc {
    font: var(--font-body-light);

    p {
      font-size: var(--font-size-body-sm);
      margin-bottom: 0;
    }

    a {
      color: var(--color-black);

      &:hover {
        color: var(--color-black);
      }
    }
  }

  .carousel-slider-nav {
    margin-bottom: 3.6rem;
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 1rem;
    }

    & .arrow-right,
    & .arrow-left {
      padding: 2rem;

      & .icon-wrapper {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    & .arrow-left .icon-wrapper svg {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 768px) {
    & {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .carousel-head {
      grid-column: auto;
    }

    .carousel-title {
      margin-bottom: 1.8rem;
    }

    .carousel-slider {
      margin-top: 3.6rem;
    }

    .carousel-slider-nav {
      margin-top: 1.2rem;
    }

    .promo-tile {
      width: 100%;
    }
  }
`;
