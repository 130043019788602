import styled from 'styled-components';

export const Wrapper = styled.div<{
  noTopPadding?: boolean;
}>`
  --font-body: var(--font-weight-normal) 2.4rem / 116% var(--font-family-heading);
  --font-sub: var(--font-weight-normal) 3.2rem / 116% var(--font-family-heading);
  --font-label-4: 400 2.4rem / 120% var(--font-family-heading);
  --font-label-3: 400 max(12px, 2rem) / 100% var(--font-family-heading);

  max-width: 164rem;
  margin: 0 auto;
  padding: 18rem 11rem 5rem;
  padding-top: ${({ noTopPadding }) => (noTopPadding ? 0 : undefined)};
  .head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // allow to expand beyond the container when the h2 font-size exceeds the clamped maximum
    margin: 0 min((var(--font-size-h2) - 17rem) * (-87 / 17) * 0.5, 0px);

    .head-body {
      font: var(--font-body);
      max-width: 39rem;
      padding-left: 6rem;
    }

    &.commerce-head {
      justify-content: center;
    }
  }

  .commerce-body {
    display: grid;
    grid-template-columns: 6.4fr 3.6fr;
    grid-column-gap: 13rem;
    padding-top: 15rem;
    align-items: flex-start;
  }

  .commerce-copy-slider {
    padding-top: 3.6rem;
  }

  .commerce-copy-slide {
    .heading.inline {
      margin: 0;
      font: var(--font-h3);
      margin-right: 3.6rem;
    }

    .text-base {
      font-size: var(--font-size-body-sm);
      a {
        color: currentColor;
      }
    }
  }

  .commerce-fine-print {
    > .block {
      > .heading.inline {
        line-height: 0.8em;
      }
    }
  }

  .commerce-side {
    display: flex;
    flex-direction: column;
  }

  .commerce-slider {
    height: 0;
    padding-top: 72.55%;
    width: 100%;
    border-radius: 3rem;
    position: relative;
    overflow: hidden;
  }

  .phone-slider {
    min-height: 70rem;
  }

  .commerce-slide {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease opacity;

    &.active {
      opacity: 1;
    }
  }

  .commerce-side-title,
  .head-sub {
    font: var(--font-sub);
  }

  .head-sub {
    display: none;
  }

  .commerce-nav {
    font: var(--font-label-3);
    margin-top: 6.6rem;

    & > * + * {
      margin-top: 3.2rem;
    }
  }

  .phone-carousel-nav {
    font: var(--font-label-4);
  }

  .commerce-nav-indicator {
    position: absolute;
    width: 35%;
    height: 1px;
    bottom: calc(100% + 1.2rem - 1px);
    display: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: currentColor;
    }

    &::before {
      opacity: 0.2;
      width: 100%;
    }

    &::after {
      width: var(--progress);
    }
  }

  .commerce-nav-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    opacity: 0.25;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;

    &.active {
      opacity: 1;

      .commerce-nav-indicator {
        display: block;
      }
    }

    & > .commerce-nav-item-number + * {
      margin-left: 2rem;
    }
  }

  .commerce-divider {
    margin-top: 9.6rem;
    height: 1px;
    background: #000;
    opacity: 0.5;
    width: 100%;
  }

  .features-body {
    padding-top: 15rem;
  }

  .features-nav {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 9rem;
  }

  .features-nav {
    font: var(--font-label-4);
  }

  .features-nav-indicator {
    grid-area: 1/1/1/1;
    position: absolute;
    width: 60%;
    height: 1px;
    bottom: calc(100% + 2.4rem);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: currentColor;
    }

    &::before {
      opacity: 0.2;
      width: 100%;
    }

    &::after {
      width: var(--progress);
    }
  }

  .features-nav-item {
    display: block;
    text-align: left;
    margin: 0;
    padding: 0;
    appearance: none;
    opacity: 0.25;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.072rem;

    &.active {
      opacity: 1;
    }
  }

  .features-nav-item-title {
    padding-top: 1rem;
  }

  .features-slider {
    margin-top: 8rem;
    position: relative;
    padding-top: 64.5338%;
  }

  .features-slide {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease opacity;

    &.active {
      opacity: 1;
    }
  }

  .slide-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  .phone-screen {
    max-width: 45rem;
  }

  .commerce-nav-item-mobile,
  .features-nav-item-mobile,
  .commerce-copy-slider-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    --font-body: var(--font-weight-normal) 1.8rem / 116% var(--font-family-heading);
    --font-sub: var(--font-weight-normal) 1.9rem / 116% var(--font-family-heading);

    padding: 9rem 2rem 5rem;

    .head {
      flex-direction: column;
      align-items: flex-start;

      .head-body {
        max-width: 24rem;
        padding-top: 3.2rem;
        align-self: flex-end;
        padding-left: 0;
      }
    }

    .commerce-copy-slider-mobile {
      display: inherit;
      padding-top: 5rem;
    }

    .commerce-nav-item-mobile,
    .features-nav-item-mobile {
      display: block;
      padding-top: 1rem;
    }

    .features-nav,
    .features-nav-item-mobile,
    .commerce-nav-item-mobile {
      font: var(--font-label-3);
    }

    .features-nav {
      display: flex;
      grid-column-gap: 0;
    }

    .features-nav-item {
      flex-basis: 15vw;
      transition: 0.3s ease all;

      &.active {
        flex-basis: 30vw;
      }
    }

    .features-nav-indicator,
    .commerce-nav-indicator {
      bottom: calc(100% + 2rem);
    }

    .commerce-nav-indicator {
      width: 15vw;
    }

    .features-nav-indicator {
      width: 22vw;
    }

    .features-nav-item-title {
      display: none;
    }

    .features-body {
      padding-top: 6.8rem;
    }

    .features-slider {
      margin-top: 4rem;
    }

    .commerce-slide,
    .features-slide {
      padding-top: 86.72316%;
    }

    .head.commerce-head {
      text-align: center;
      align-items: center;
    }

    .head-sub {
      padding-top: 2.6rem;
      display: inherit;
      max-width: 27rem;
      margin: 0 auto;
    }

    .commerce-body {
      padding-top: 6rem;
      display: flex;
      flex-direction: column-reverse;
    }

    .commerce-side {
      width: 100%;
    }

    .commerce-nav {
      margin-top: 0;
      display: flex;

      & > * + * {
        margin-top: 0;
      }
    }

    .commerce-divider,
    .commerce-side-title,
    .commerce-copy-slider {
      display: none;
    }

    .commerce-slider {
      width: 100%;
      margin-top: 4rem;
    }

    .commerce-copy-slide {
      &.last {
        > .block {
          display: flex;
        }

        .heading.inline {
          float: none;
        }
      }
    }

    .commerce-nav-item {
      flex-basis: 15vw;

      & > .commerce-nav-item-number + * {
        margin-left: 0;
      }
    }

    .commerce-nav-item-title {
      display: none;
    }
  }
`;
