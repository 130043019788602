import React from 'react';

import CTAButton from '@/components/Blocks/CTAButton';
import MediaWithMeta from '@/components/Blocks/MediaWithMeta';
import OneColumnText from '@/components/Blocks/OneColumnText';
import PromoTile from '@/components/Blocks/PromoTile';
import SocialShareLinks from '@/components/Blocks/SocialShareLinks';
import ThreeColumnText from '@/components/Blocks/ThreeColumnText';
import TitleAndBlurb from '@/components/Blocks/TitleAndBlurb';
import TwoColumnText from '@/components/Blocks/TwoColumnText';
import { CookieEditor } from '@/components/CookieEditor/CookieEditor';
import CreatorCarousel from '@/components/Creators/CreatorCarousel';
import CreatorHero from '@/components/Creators/CreatorHero';
import TitleAndLogoLockup from '@/components/Creators/TitleAndLogoLockup';
import Error from '@/components/Error/Error';
import { Faq } from '@/components/Faq';
import CreatorGrid from '@/components/Features/CreatorGrid';
import FeaturesHero from '@/components/Features/FeaturesHero';
import LogoShowcase from '@/components/Features/LogoShowcase';
import RelatedArticles from '@/components/Features/RelatedArticles';
import FullBleedTestimonial from '@/components/Homepage/FullBleedTestimonial';
import GetStarted from '@/components/Homepage/GetStarted';
import ImageGridWithTitle from '@/components/Homepage/ImageGridWithTitle';
import FloatingProductUI from '@/components/Hydra/FloatingProductUI';
import HomeHero from '@/components/Hydra/HomeHero';
import IntegrationPromoTilesCarousel from '@/components/Integrations/IntegrationPromoTilesCarousel';
import IntegrationPromoTilesDirectory from '@/components/Integrations/IntegrationPromoTilesDirectory';
import PolicyCards from '@/components/PolicyCards';
import { PolicySectionContainer } from '@/components/PolicySectionContainer';
import PricingCommerceCarousel from '@/components/Pricing/PricingCommerceCarousel';
import PricingFeaturesCarousel from '@/components/Pricing/PricingFeaturesCarousel';
import PricingPlans from '@/components/Pricing/PricingPlans';
import PricingTextRichCards from '@/components/Pricing/PricingTextRichCards';
import StrapiStub from '@/components/StrapiStub';

import type {
  CallToActionButtonProps,
  CreatorHeroProps,
  CreatorCarouselProps,
  CreatorGridProps,
  FloatingProductUIProps,
  HomeHeroProps,
  ImageGridWithTitleProps,
  FullBleedTestimonialProps,
  FeaturesHeroProps,
  GetStartedProps,
  StrapiComponent,
  UtilsOneColumnTextProps,
  UtilsThreeColumnTextProps,
  UtilsTwoColumnTextProps,
  UtilsFourColumnText,
  MediaWithMetaProps,
  PromoTileProps,
  TitleAndBlurbProps,
  SocialShareLinksProps,
  RelatedArticlesProps,
  TitleAndLogoLockupProps,
  IntegrationPromoTilesDirectoryProps,
  PricingTextRichCardsProps,
  PricingPlansProps,
  IntegrationPromoTilesCarouselProps,
  PricingFeaturesCarouselProps,
  PricingCommerceCarouselProps,
  LogoShowcaseProps,
  CookieEditorProps,
  PolicySectionContainerProps,
  PolicyCardsProps,
  ErrorProps,
  FaqProps,
  TitleBlockProps,
  RichTextCardProps,
  PhoneScreenCarouselProps,
  CarouselProps,
} from '@/utilities/strapi/types/componentTypes';

import RichTextCards from '../Articles/RichTextCards';
import { FourColumnText } from '../Blocks/FourColumnText';
import { Carousel } from '../Features/Carousel';
import { PhoneScreenCarousel } from '../PhoneScreenCarousel/PhoneScreenCarousel';
import { TitleBlock } from '../TitleBlock/TitleBlock';

export const renderStrapiComponent = (component: StrapiComponent) => {
  const { __component: id, ...props } = component;
  switch (id) {
    case 'articles.related-articles':
      return <RelatedArticles {...(props as RelatedArticlesProps)} />;
    case 'articles.social-share-links':
      return <SocialShareLinks {...(props as SocialShareLinksProps)} />;
    case 'utils.media-with-meta':
      return <MediaWithMeta {...(props as MediaWithMetaProps)} />;
    case 'utils.title-block':
      return <TitleBlock {...(props as TitleBlockProps)} />;
    case 'articles.promo-tile':
      return <PromoTile {...(props as PromoTileProps)} />;
    case 'articles.title-and-blurb':
      return <TitleAndBlurb {...(props as TitleAndBlurbProps)} />;
    case 'careers.job-board':
      return <div id="ashby_embed" />;
    case 'creators.creator-hero':
      return <CreatorHero {...(props as CreatorHeroProps)} />;
    case 'creators.creator-carousel':
      return <CreatorCarousel {...(props as CreatorCarouselProps)} />;
    case 'creators.title-and-logo-lockup':
      return <TitleAndLogoLockup {...(props as TitleAndLogoLockupProps)} />;
    case 'articles.one-column-text':
      return <OneColumnText {...(props as UtilsOneColumnTextProps)} />;
    case 'articles.two-column-text':
      return <TwoColumnText {...(props as UtilsTwoColumnTextProps)} />;
    case 'articles.three-column-text':
      return <ThreeColumnText {...(props as UtilsThreeColumnTextProps)} />;
    case 'articles.four-column-section':
      return <FourColumnText {...(props as UtilsFourColumnText)} />;
    case 'articles.faq':
      return <Faq {...(props as FaqProps)} />;
    case 'articles.rich-text-cards':
      return <RichTextCards {...(props as RichTextCardProps)} />;
    case 'utils.call-to-action-button':
      return <CTAButton {...(props as CallToActionButtonProps)} />;
    case 'utils.cookie-edit-button':
      return <CookieEditor {...(props as CookieEditorProps)} />;
    case 'homepage.image-grid-with-title':
      return <ImageGridWithTitle {...(props as ImageGridWithTitleProps)} />;
    case 'homepage.large-quote-and-title':
      return <FullBleedTestimonial {...(props as FullBleedTestimonialProps)} />;
    case 'marketing.get-started':
      return <GetStarted {...(props as GetStartedProps)} />;
    case 'homepage.home-hero':
      return <HomeHero {...(props as HomeHeroProps)} />;
    case 'marketing.floating-product-ui':
      return <FloatingProductUI {...(props as FloatingProductUIProps)} />;
    case 'features.carousel':
      return <Carousel {...(props as CarouselProps)} />;
    case 'features.features-hero':
      return <FeaturesHero {...(props as FeaturesHeroProps)} />;
    case 'features.creator-grid':
      return <CreatorGrid {...(props as CreatorGridProps)} />;
    case 'features.logo-showcase':
      return <LogoShowcase {...(props as LogoShowcaseProps)} />;
    case 'features.phone-screen-carousel':
      return <PhoneScreenCarousel {...(props as PhoneScreenCarouselProps)} />;
    case 'integrations.promo-tiles-directory':
      return <IntegrationPromoTilesDirectory {...(props as IntegrationPromoTilesDirectoryProps)} />;
    case 'pricing.title-and-rich-text-cards':
      return <PricingTextRichCards {...(props as PricingTextRichCardsProps)} />;
    case 'pricing.pricing-plans':
      return <PricingPlans {...(props as PricingPlansProps)} />;
    case 'integrations.promo-tiles-carousel':
      return <IntegrationPromoTilesCarousel {...(props as IntegrationPromoTilesCarouselProps)} />;
    case 'pricing.features-carousel':
      return <PricingFeaturesCarousel {...(props as PricingFeaturesCarouselProps)} />;
    case 'pricing.commerce-carousel':
      return <PricingCommerceCarousel {...(props as PricingCommerceCarouselProps)} />;
    case 'policy.policy-section-container':
      return <PolicySectionContainer {...(props as PolicySectionContainerProps)} />;
    case 'policy.policy-cards':
      return <PolicyCards {...(props as PolicyCardsProps)} />;
    case 'error.error':
      return <Error {...(props as ErrorProps)} />;
  }
  return <StrapiStub {...component} />;
};
