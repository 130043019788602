import clsx from 'clsx';
import { gsap } from 'gsap';
import React, { useRef } from 'react';

import styled from 'styled-components';

import ScrollHintArrow from '@/components/Homepage/ScrollHintArrow';
import useStrapiGlobals from '@/context/StrapiGlobalsContext';
import useGsapEffect from '@/hooks/useGsapEffect';
import type { CreatorHeroProps as Props, MediaWithMeta } from '@/utilities/strapi/types/componentTypes';

import { StyledStrapiLink } from '../Blocks/CTAButton';
import Video from '../Elements/Video';
import TextEditor from '../TextEditor';
import LineBreakTitle from '../utils/LineBreakTitle';

const StyledCreatorHero = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: clamp(50vw, 100vh, 70vw);

  @media (max-width: 768px) {
    min-height: max(100vh, 150vw);
  }

  .content {
    position: relative;
    width: 100%;
    min-height: clamp(50vw, 100vh, 70vw);
    padding: max(100vh - 90vw, 17rem)
      max(env(safe-area-inset-right), calc(5rem + var(--rem-scale-viewport-half-excess))) 5rem
      max(env(safe-area-inset-left), calc(5rem + var(--rem-scale-viewport-half-excess)));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
      padding: 14rem max(env(safe-area-inset-right), 2rem) calc(2rem + var(--browser-chrome-height))
        max(env(safe-area-inset-right), 2rem);
      min-height: 100vh;
    }
  }

  .flat-title {
    .title {
      padding-bottom: 12rem;

      @media (max-width: 768px) {
        padding-bottom: 2rem;

        h1 {
          margin-bottom: 0;
        }
      }
    }
  }

  .background {
    width: 100%;
    height: calc(100% + 12rem);
    position: absolute;
    left: 0;
    top: 0;

    > img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.25);
    }
  }

  .title {
    position: relative;
    width: 100%;

    h1 {
      color: var(--color-white);
      margin-bottom: 4rem;

      @media (max-width: 768px) {
        margin-bottom: 5rem;
      }
    }
  }
`;

const Body = styled.div<{ useLargeText?: boolean }>`
  max-width: ${({ useLargeText }) => (useLargeText ? '60rem' : '41.6rem')};
  color: var(--color-white);

  @media (max-width: 768px) {
    position: relative;
    bottom: auto;
    left: auto;
    max-width: 26.9rem;
  }

  .text-base {
    font-size: ${({ useLargeText }) => (useLargeText ? 'var(--font-size-h5)' : '2.4rem')};
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.048rem;

    @media (max-width: 768px) {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.016rem;
    }
  }

  ${StyledStrapiLink} {
    background-color: var(--color-action-inverse);
    color: var(--color-action-default);
    border-color: transparent;
  }
`;

function useHydraData(background: MediaWithMeta) {
  const { slugs } = useStrapiGlobals();
  const lastSlug = slugs[slugs.length - 1];
  let variation: string;
  if (lastSlug.includes('vid')) {
    variation = 'video';
  } else if (lastSlug.includes('mus')) {
    variation = 'musician';
  } else if (lastSlug.includes('pod')) {
    variation = 'podcast';
  } else if (lastSlug.includes('gam')) {
    variation = 'gaming';
  } else if (lastSlug.includes('updates') || lastSlug.includes('careers')) {
    return null;
  } else {
    variation = 'artist';
  }
  return JSON.stringify({
    type: 'CreatorHero',
    variation,
    colors: background?.media?.data?.attributes?.colors,
  });
}

export default function CreatorHero({ title, background, body, titleStyle }: Props) {
  const root = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>();
  const titleRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);

  useGsapEffect(root, () => {
    const updateProgress = (progress: number) => {
      const isMobile = window.innerWidth <= 768;
      gsap.to(titleRef.current, { y: `-${50 * progress}vh`, duration: 0.5 });
      gsap.to(bodyRef.current, { y: `-${(isMobile ? 50 : 25) * progress}vh`, duration: 0.5 });
      gsap.to(bgRef.current, { y: `-${12 * progress}rem`, duration: 0.5 });
    };

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: root.current,
        // pin: true,
        start: 'top',
        end: 'bottom',
        scrub: false,
        onUpdate: (self) => updateProgress(self.progress),
      },
    });
  });

  const data = useHydraData(background);

  return (
    <div>
      <StyledCreatorHero data-hydra={data} ref={root}>
        <div ref={bgRef} className="background">
          {background && <Video media={background} crossOrigin="anonymous" />}
        </div>
        <div className={clsx('content', { 'flat-title': titleStyle === 'flat' })}>
          <div ref={titleRef} className="title">
            <LineBreakTitle align={titleStyle === 'flat' ? 'space-between' : undefined} size={1}>
              {title}
            </LineBreakTitle>
          </div>
          <Body ref={bodyRef} useLargeText={titleStyle === 'flat'}>
            <TextEditor {...body} />
          </Body>

          <ScrollHintArrow sectionRef={root} className="arrow" />
        </div>
      </StyledCreatorHero>
    </div>
  );
}
